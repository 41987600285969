<template>
  <div class="banner" :style="bannerStyles" :class="{ 'ph-loading--content': loading }">
    <div class="banner__title">
      {{ banner.title }}
    </div>
    <action-view class="banner__button-action" @click="onActionClick">
      {{ banner.button }}
    </action-view>
    <cross-button :color="crossIconColor" class="banner__button-cross" @click="onCloseClick" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CrossButton from '@/elements/components/CrossButton';
import ActionView from '@/elements/atoms/ActionView';

export default {
  name: 'Banner',
  components: {
    ActionView,
    CrossButton,
  },
  props: {
    banner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    image() {
      return this.isMobileScreen ? this.banner.mobileImage : this.banner.image;
    },
    crossIconColor() {
      return this.isMobileScreen ? 'blue' : 'white';
    },
    bannerStyles() {
      if (this.loading) {
        return;
      }

      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
  methods: {
    ...mapActions('UserModule', ['closeBanner']),
    onActionClick() {
      this.closeBanner(this.banner.id);
      window.open(this.banner.link, '_blank');
    },
    onCloseClick() {
      this.closeBanner(this.banner.id);
    },
  },
  created() {
    const backgroundImg = new Image();
    backgroundImg.src = this.image;
    backgroundImg.onload = () => {
      this.loading = false;
    };
  },
};
</script>

<style scoped lang="sass">
$cross-icon-gap-mobile: base-unit(10)
$cross-icon-gap-desktop: base-unit(5)
$light-blue: #9BBDDF

.banner
  display: flex
  justify-content: space-between
  position: relative
  border-radius: base-unit(4)
  padding: base-unit(20) base-unit(36) base-unit(20) base-unit(16)
  margin-right: base-unit(4)
  background-size: cover
  flex-direction: column
  +medium-devices
    max-width: base-unit(240)
    border-radius: base-unit(10)
    width: 100%
    justify-content: unset
    padding: base-unit(20) base-unit(12)
    margin-right: 0

  &__title
    font-weight: 700
    font-size: base-unit(16)
    color: white

  &__button
    &-action
      height: base-unit(36)
      background-color: white
      color: blue-color('primary')
      padding: base-unit(4) base-unit(20)
      border-radius: base-unit(100)
      margin-top: base-unit(10)
      font-size: base-unit(12)
      +medium-devices
        color: white
        background-color: rgba(255, 255, 255, 0.2)
        margin-top: base-unit(5)

    &-cross
      width: base-unit(24)
      height: base-unit(24)
      position: absolute
      right: -1 * $cross-icon-gap-mobile
      top: -1 * $cross-icon-gap-mobile
      background-color: white
      border-radius: base-unit(5)
      z-index: 5
      +medium-devices
        border-radius: unset
        background-color: initial
        right: $cross-icon-gap-desktop
        top: $cross-icon-gap-desktop
</style>
