<template>
  <transition-group name="collapse-list" tag="div" class="banner-container">
    <banner class="banner-container__item" v-for="banner in activeBanners" :key="banner.id" :banner="banner" />
  </transition-group>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

import Banner from '@/elements/components/banners/Banner';

export default {
  name: 'BannerContainer',
  components: { Banner },
  computed: {
    ...mapState('UserModule', ['banners', 'disabledBanners']),
    disabledBannerIds() {
      return this.disabledBanners.map(({ id }) => id);
    },
    activeBanners() {
      return this.banners.filter(({ id }) => !this.disabledBannerIds.includes(id));
    },
  },
  methods: {
    ...mapActions('UserModule', ['getBanners', 'showBanner']),
  },
  async created() {
    const now = moment();
    await Promise.all(
      this.disabledBanners
        .filter(({ expiredAt }) => moment(expiredAt).isBefore(now))
        .map(({ id }) => this.showBanner(id)),
    );

    try {
      await this.getBanners();
      // eslint-disable-next-line no-empty
    } catch {}
  },
};
</script>

<style scoped lang="sass">
.banner-container
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  +medium-devices
    overflow-y: auto
    overflow-x: hidden
    padding-right: base-unit(5)

    &::-webkit-scrollbar
      width: base-unit(6)

    &::-webkit-scrollbar-track
      -webkit-box-shadow: inset 0 0 base-unit(3) rgba(0,0,0,0.3)
      border-radius: base-unit(8)

    &::-webkit-scrollbar-thumb
      border-radius: base-unit(8)
      background: white
      -webkit-box-shadow: inset 0 0 base-unit(3) rgba(0,0,0,0.3)

  &__item
    margin-bottom: base-unit(18)
    transition: 0.3s linear

    &:last-child
      margin-bottom: 0

.collapse-list-move
  transition: all 0.5s

.collapse-list-item
  transition: all 0.5s

.collapse-list-leave-to
  max-height: 0
  padding-top: 0
  padding-bottom: 0
  overflow: hidden
  opacity: 0
  +medium-devices
    max-height: initial
    flex: 0

.collapse-list-leave
  max-height: base-unit(70)
  opacity: 1
  +medium-devices
    max-height: initial
    flex: 1
</style>
